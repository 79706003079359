// src/components/modals/LoginModal.js

import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSignInMutation } from "../../Redux/ApiSlice";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { setCredentials } from "../../Redux/userSlice";
import ForgetModel from "../Modal/Forgetpassword";

const LoginModal = ({ isModalOpen, closeModal, openRegisterModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginUser, { isLoading: isLoginLoading }] = useSignInMutation();
  const [isForgetModalOpen, setisForgetModalOpen] = useState(false);

  // Function to open the modal
  const openForgetPasswordModal = () => {
    setisForgetModalOpen(true);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Validation schema for login
  const loginValidationSchema = Yup.object({
    user_name: Yup.string().required("username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleLogin = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    const loginUserDetails = {
      user_name: values.user_name,
      password: values.password,
    };

    try {
      const result = await loginUser(loginUserDetails).unwrap();
      const { token, user } = result;

      dispatch(setCredentials({ token, user }));
      navigate("/");
      resetForm(); // Redirect to home or desired page
      setSubmitting(false);
      closeModal();
    } catch (error) {
      console.error("Login failed:", error);
      setErrors({
        general: error.data?.message || "Login failed. Please try again.",
      });
      setSubmitting(false);
    }
  };
  const closeForgetModal = () => {
    setisForgetModalOpen(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "modal-background") {
      closeModal();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div
      id="modal-background"
      className="fixed inset-0  bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="dark:bg-custon-right-side-bg bg-white px-6 mx-3 lg:mx-0 lg:mt-0 py-3 rounded-xl h-[549px] overflow-scroll lg:h-[570px] shadow-lg w-[440px] lg:w-[660px] your-scroll-container-class">
        {/* Close Button */}
        <div className="flex justify-end items-center">
          <ImCross
            onClick={closeModal}
            className="dark:bg-black bg-white rounded-full p-2 text-[30px] dark:text-white text-black cursor-pointer"
          />
        </div>

        {/* Logo */}
        <div className="flex justify-center items-center">
          <img
            src="https://res.cloudinary.com/djlpb1ld5/image/upload/v1727980998/WhatsApp_Image_2024-10-03_at_23.37.42_4b7305c4_nfutdo.jpg"
            alt="logo"
            className="h-[50px] w-[200px]"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
          {/* Illustration */}
          <div className="my-7 hidden lg:block">
            <img
              src="https://cdn.cloudd.site/theme/khelo-bharat_theme/khelo-bharat/assets/images/login-img-new-dark.png"
              alt="Login"
            />
            <p className="font-[700] text-[22px] font-montserrat text-center text-custom-yellow">
              100% FAST Withdrawal
            </p>
            <img
              src="https://cdn.cloudd.site/content/assets/images/login-payment-dark.png?v=1.0.4"
              alt="Footer"
              className="h-[20px] w-full"
            />
          </div>

          {/* Login Form */}
          <div className="my-7">
            <Formik
              initialValues={{
                user_name: "",
                password: "",
                keepMeSignedIn: false,
              }}
              validationSchema={loginValidationSchema}
              onSubmit={handleLogin}
            >
              {({ isSubmitting, errors }) => (
                <Form className="space-y-3 mt-3">
                  {/* Email Field */}
                  <div className="my-6 relative">
                    <label
                      htmlFor="user_name"
                      className="block absolute top-1 text-[12px] left-4 text-custom-yellow"
                    >
                      Enter the username
                    </label>
                    <Field
                      type="text"
                      name="user_name"
                      id="user_name"
                      className="border-[1px] border-gray-700 dark:bg-black bg-gray-100 rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                      placeholder=""
                    />
                    <ErrorMessage
                      name="user_name"
                      component="div"
                      className="text-red-500 text-sm px-3"
                    />
                  </div>

                  {/* Password Field */}
                  <div className="my-6 relative">
                    <label
                      htmlFor="password"
                      className="block absolute top-1 text-[12px] left-4 text-custom-yellow"
                    >
                      Enter the Password
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="border-[1px] border-gray-700 dark:bg-black bg-gray-100  rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                      placeholder=""
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm px-3"
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className="absolute right-4 top-6 cursor-pointer"
                    >
                      {showPassword ? (
                        <BsEyeFill className="text-custom-yellow" />
                      ) : (
                        <BsEyeSlashFill className="text-custom-yellow" />
                      )}
                    </span>
                  </div>

                  {/* Keep Me Signed In & Forgot Password */}
                  <div className="flex justify-between">
                    <label className="text-custom-yellow flex items-center">
                      <Field
                        type="checkbox"
                        name="keepMeSignedIn"
                        className="mr-2"
                      />
                      Keep me signed in
                    </label>
                    <span
                      onClick={openForgetPasswordModal}
                      className="text-gray-400 cursor-pointer hover:underline"
                    >
                      Forgot password?
                    </span>
                  </div>

                  {/* Submit Button */}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="w-[230px] bg-custom-yellow text-black py-3 px-4 rounded-3xl"
                      disabled={isSubmitting || isLoginLoading}
                    >
                      {isLoginLoading ? "Logging in..." : "Login"}
                    </button>
                  </div>

                  {/* General Error Message */}
                  {errors.general && (
                    <div className="text-red-500 text-center">
                      {errors.general}
                    </div>
                  )}
                </Form>
              )}
            </Formik>

            {/* Switch to Register */}
            <div className="text-center">
              <p className="mt-4 text-[14px] text-gray-400">
                Don't have an account?{" "}
                <span
                  className="text-custom-yellow cursor-pointer"
                  onClick={() => {
                    closeModal();
                    openRegisterModal();
                  }}
                >
                  Register
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ForgetModel
        isModalOpen={isForgetModalOpen}
        closeModal={closeForgetModal}
      />
    </div>
  );
};

export default LoginModal;
