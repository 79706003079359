// fetchHelper.jsx
import { useQuery } from "@tanstack/react-query";

export const useFetch = (endpoint) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["repoData", endpoint],
    queryFn: () =>
      fetch(`https://api.hattricks.games${endpoint}`).then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      }),
  });

  return { data, isLoading, error };
};
