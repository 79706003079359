import { useDispatch } from "react-redux";
import { setBetInfo } from "../Redux/features/betSlice";
export function getFormattedDate(date) {
  const isoDate = new Date(date);

  // Get the day, month, and time in the required format
  const day = isoDate.getDate();
  const month = isoDate.toLocaleString("en-US", { month: "short" }); // Oct
  const hours = isoDate.getHours().toString().padStart(2, "0"); // 24-hour format with leading zero
  const minutes = isoDate.getMinutes().toString().padStart(2, "0"); // Add leading zero to minutes if necessary

  // Format the result
  const formattedDate = `${day} ${month} - ${hours}:${minutes}`;

  return formattedDate;
}

export function getNormalizedFancyData(raw) {
  const fancyDataSet = new Set();
  const categorizedData = {};
  raw?.forEach((data) => {
    const existingData = JSON.parse(data);
    const selectionId = existingData?.SelectionId;
    const srNo = existingData?.sr_no;
    if (!fancyDataSet.has(selectionId)) {
      fancyDataSet.add(selectionId);

      if ([8, 6, 10].includes(srNo)) {
        if (!categorizedData["Players"]) {
          categorizedData["Players"] = [];
        }
        categorizedData["Players"].push(existingData);
      } else if ([1, 4].includes(srNo)) {
        if (!categorizedData["Runs"]) {
          categorizedData["Runs"] = [];
        }
        categorizedData["Runs"].push(existingData);
      } else if ([5].includes(srNo)) {
        if (!categorizedData["Wickets"]) {
          categorizedData["Wickets"] = [];
        }
        categorizedData["Wickets"].push(existingData);
      } else {
        if (!categorizedData["Normal"]) {
          categorizedData["Normal"] = [];
        }
        categorizedData["Normal"].push(existingData);
      }
    }
  });
  return categorizedData;
}

export function getFancyPrices(data) {
  const fancyLayData = [];
  const fancyBackData = [];
  const layMap = new Map();
  const backMap = new Map();
  if (data) {
    Object.keys(data).forEach((elem) => {
      data[elem]?.map((elem) => {
        const selectionId = elem?.SelectionId;

        // Handle Lay Data
        if (!layMap.has(selectionId)) {
          layMap.set(selectionId, [
            {
              price: elem?.LayPrice1,
              size: elem?.LaySize1,
              status: elem?.GameStatus,
            },
            {
              price: elem?.LayPrice2,
              size: elem?.LaySize2,
              status: elem?.GameStatus,
            },
            {
              price: elem?.LayPrice3,
              size: elem?.LaySize3,
              status: elem?.GameStatus,
            },
          ]);
        } else {
          // Add unique prices and sizes only if they don't exist
          const existingLayPrices = layMap.get(selectionId);
          const newLayEntries = [
            {
              price: elem?.LayPrice1,
              size: elem?.LaySize1,
              status: elem?.GameStatus,
            },
            {
              price: elem?.LayPrice2,
              size: elem?.LaySize2,
              status: elem?.GameStatus,
            },
            {
              price: elem?.LayPrice3,
              size: elem?.LaySize3,
              status: elem?.GameStatus,
            },
          ];
          newLayEntries.forEach((newLay) => {
            // Check for duplicates based on price and size
            if (
              !existingLayPrices.some(
                (existingLay) =>
                  existingLay.price === newLay.price &&
                  existingLay.size === newLay.size
              )
            ) {
              existingLayPrices.push(newLay);
            }
          });
        }

        // Handle Back Data
        if (!backMap.has(selectionId)) {
          backMap.set(selectionId, [
            {
              price: elem?.BackPrice1,
              size: elem?.BackSize1,
              status: elem?.GameStatus,
            },
            {
              price: elem?.BackPrice2,
              size: elem?.BackSize2,
              status: elem?.GameStatus,
            },
            {
              price: elem?.BackPrice3,
              size: elem?.BackSize3,
              status: elem?.GameStatus,
            },
          ]);
        } else {
          // Add unique prices and sizes only if they don't exist
          const existingBackPrices = backMap.get(selectionId);
          const newBackEntries = [
            {
              price: elem?.BackPrice1,
              size: elem?.BackSize1,
              status: elem?.GameStatus,
            },
            {
              price: elem?.BackPrice2,
              size: elem?.BackSize2,
              status: elem?.GameStatus,
            },
            {
              price: elem?.BackPrice3,
              size: elem?.BackSize3,
              status: elem?.GameStatus,
            },
          ];
          newBackEntries.forEach((newBack) => {
            // Check for duplicates based on price and size
            if (
              !existingBackPrices.some(
                (existingBack) =>
                  existingBack?.price === newBack?.price &&
                  existingBack?.size === newBack?.size
              )
            ) {
              existingBackPrices.push(newBack);
            }
          });
        }
      });
    });
  }

  // Convert maps to desired array format
  fancyLayData.push(
    ...Array.from(layMap, ([key, value]) => ({ [key]: value }))
  );
  fancyBackData.push(
    ...Array.from(backMap, ([key, value]) => ({ [key]: value }))
  );
  return [fancyLayData, fancyBackData];
}

export const isMobile = () =>
  /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
    navigator.userAgent
  );

export const calculateProfit = (
  odds,
  stake,
  selectionId,
  matchOddsType,
  otherTeamSelectionId,
  betAction
) => {
  let profit_amount = 0;
  let loss_amount = 0;
  let total_amount = 0;

  switch (betAction) {
    case "back":
      let backNewOdds = 0;
      backNewOdds = convertIntToDecimal(odds);
      total_amount = stake * backNewOdds;
      loss_amount = total_amount / backNewOdds;
      profit_amount = Math.abs(total_amount - loss_amount);
      if (matchOddsType === "Fancy Odds") {
        return {
          [selectionId]: total_amount + stake,
        };
      }
      return {
        [selectionId]: profit_amount,
        [otherTeamSelectionId]: -Number(loss_amount),
      };
    case "lay":
      let layNewOdds = 0;
      layNewOdds = convertIntToDecimal(odds);
      total_amount = stake * layNewOdds;
      loss_amount = total_amount / layNewOdds;
      profit_amount = total_amount - loss_amount;
      if (matchOddsType === "Fancy Odds") {
        return {
          [selectionId]: -Number(loss_amount),
        };
      }
      return {
        [selectionId]: -Number(loss_amount),
        [otherTeamSelectionId]: Math.abs(profit_amount),
      };

    default:
      /* No case here return null */
      return null;
  }
};

export const convertIntToDecimal = (num) => {
  let newOdds = 0;
  // Number.isInteger(odds) && odds <= 10
  if (Number.isInteger(num) && num > 10) {
    newOdds = num / 100;
  } else {
    newOdds = num;
  }
  return newOdds.toFixed(2);
};

export const getBetAmount = (betData, matchSelectionId) => {
  console.log(betData);
  if (
    betData?.profit?.selectionId === matchSelectionId &&
    betData.profit.amount
  ) {
    return betData.profit.amount.toFixed(2);
  }

  if (betData?.loss?.selectionId === matchSelectionId && betData.loss.amount) {
    return betData.loss.amount.toFixed(2);
  }

  return "";
};
