import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useForgotPasswordMutation } from "../../Redux/ApiSlice"; // Assuming you have an API setup for forgot password

const ChangePasswordModal = ({ isModalOpen, closeModal }) => {
  const dispatch = useDispatch();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  // Validation schema for forgot password
  const forgotPasswordValidationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleForgotPassword = async (values, { setSubmitting, setErrors }) => {
    try {
      // Call the forgot password mutation
      const result = await forgotPassword({ email: values.email }).unwrap();
      setSubmitting(false);
      closeModal();
      // Optionally show a success message
      alert("Password reset link sent to your email");
    } catch (error) {
      console.error("Forgot password request failed:", error);
      setErrors({
        general:
          error.data?.message || "Failed to send reset link. Please try again.",
      });
      setSubmitting(false);
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "modal-background") {
      closeModal();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div
      id="modal-background"
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="bg-custon-right-side-bg px-6 mx-3 lg:mx-0 lg:mt-0 py-3 rounded-xl h-[400px] shadow-lg w-[440px] lg:w-[500px]">
        {/* Close Button */}
        <div className="flex justify-end items-center">
          <ImCross
            onClick={closeModal}
            className="dark:bg-black bg-white rounded-full p-2 text-[30px] text-white cursor-pointer"
          />
        </div>

        {/* Title */}
        <h2 className="text-center text-custom-yellow font-bold text-2xl">
          Change Password
        </h2>

        {/* Forgot Password Form */}
        <div className="my-3">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={handleForgotPassword}
          >
            {({ isSubmitting, errors }) => (
              <Form className="space-y-3 mt-3">
                {/* Email Field */}
                <div className="my-6 relative">
                  <label
                    htmlFor="email"
                    className="block absolute top-1 text-[12px] left-4 text-custom-yellow"
                  >
                    Enter Your Current Password
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm px-3"
                  />
                </div>
                <div className="my-6 relative">
                  <label
                    htmlFor="email"
                    className="block absolute top-1 text-[12px] left-4 text-custom-yellow"
                  >
                    Enter New Password
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm px-3"
                  />
                </div>
                <div className="my-6 relative">
                  <label
                    htmlFor="email"
                    className="block absolute top-1 text-[12px] left-4 text-custom-yellow"
                  >
                    Re-type Password
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className="border-[1px] border-gray-700 bg-black rounded-3xl text-[14px] px-4 pt-5 pb-2 w-full text-gray-300"
                    placeholder=""
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm px-3"
                  />
                </div>

                {/* Submit Button */}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="w-[230px] bg-custom-yellow text-black py-2 px-4 rounded-3xl"
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading ? "Sending..." : "Submit"}
                  </button>
                </div>

                {/* General Error Message */}
                {errors.general && (
                  <div className="text-red-500 text-center">
                    {errors.general}
                  </div>
                )}
              </Form>
            )}
          </Formik>

          {/* Cancel Button */}
          <div className="text-center mt-4">
            <button
              className="text-custom-yellow cursor-pointer hover:underline"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
