// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access_token: null,
  refresh_token: null,
  user_details: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, refreshToken } = action.payload; // Make sure payload structure is correct
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
    },

    setUserDetails: (state, action) => {
      state.user_details = action.payload;
    },
    clearCredentials: (state) => {
      state.access_token = null;
      state.refresh_token = null;
    },
  },
});

export const { setCredentials, clearCredentials, setUserDetails } =
  userSlice.actions;
export default userSlice.reducer;
