import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  event_id: "",
  event_type: "",
  market_id: "",
  selectionId: "",
  team_name: "",
  other_team_name: "",
  otherTeamSelectionId: "",
};
const matchSlice = createSlice({
  name: "matchDetails",
  initialState,
  reducers: {
    setMatchInfo: (state, action) => {
      state.event_id = action.payload.event_id;
      state.event_type = action.payload.event_type;
      state.market_id = action.payload.market_id;
      state.selectionId = action.payload.selectionId;
      state.team_name = action.payload.team_name;
      state.other_team_name = action.payload.other_team_name;
      state.otherTeamSelectionId = action.payload.otherTeamSelectionId;
    },
    clearMatchInfo: (state, action) => {
      state.event_id = "";
      state.event_type = "";
      state.market_id = "";
      state.selectionId = "";
      state.otherTeamSelectionId = "";
    },
  },
});

export const { setMatchInfo, clearMatchInfo } = matchSlice.actions;
export default matchSlice.reducer;