import React, { useEffect, useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoTv } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { FaRegEye } from "react-icons/fa";
import LoginModal from "../../component/Modal/LoginModel";
import RegisterModal from "../../component/Modal/RegisterModel";
import { BsGraphDown } from "react-icons/bs";
import { useSocket } from "../../context/SocketContext";
import { useLocation } from "react-router-dom";
import {
  cricketMenuItems,
  LIVE_BASE_URL,
  otherSportsMenuItems,
  SPORT,
} from "../../data/constants";
import { useUserContext } from "../../context/userContext";
import {
  getFancyPrices,
  getNormalizedFancyData,
  isMobile,
} from "../../data/helper";
import { BetBox } from "../../component/BetBox/BetBox";
import { useDispatch, useSelector } from "react-redux";
import { setMatchInfo } from "../../Redux/features/matchDetailsSlice";
import { selectBetData } from "../../Redux/selector";
import { clearBetInfo } from "../../Redux/features/betSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFetch } from "../../utils/fetchHelper";

function Sport_by() {
  // TODO : Remove Unnecessary state updates here. Move to redux for central State management.
  const [activeTab, setActiveTab] = useState(0);
  const [showContent, setShowContent] = useState(false);
  const { isUserLoggedIn } = useUserContext();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [activemobileTab, setActivemobileTab] = useState(0);
  const [matchOddsData, setMatchOddsData] = useState(null);
  const [bookMakerData, setBookmakerData] = useState(null);
  const [fancyOdds, setFancyOdds] = useState(null);
  const [layFancyData, setLayFancyData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(["Normal"]);
  const [teamName, setTeamName] = useState("");
  const [backFancyData, setBackFancyData] = useState([]);
  const location = useLocation();
  const [activeToggle, setActiveToggle] = useState({});
  const [lastSelectionId, setLastSelectionId] = useState();
  const queryParams = new URLSearchParams(location.search);
  const [betType, setBetType] = useState("back");
  const [oddValue, setOddValue] = useState(0.0);
  const [gType, setGtype] = useState("");
  const [matchOddType, setMatchOddType] = useState();
  const eventId = queryParams.get("id");
  const eventType = queryParams.get("sport_type");
  const socket = useSocket();
  const dispatch = useDispatch();
  const betData = useSelector(selectBetData);
  const { isLoading, data, error } = useFetch(
    `/matches/getSessions/${eventType}/${eventId}`
  );

  useEffect(() => {
    let categorizedData = null;
    if (!isLoading) {
      if (!categorizedData) {
        categorizedData = getNormalizedFancyData(data);
        console.log(categorizedData);
        setFancyOdds(categorizedData);
      }
    }
  }, [isLoading]);
  const handleBetAction = (
    betType,
    matchOddType,
    oddValue,
    selectionId,
    teamName
  ) => {
    setBetType(betType);
    setTeamName(teamName);
    dispatch(clearBetInfo());
    setMatchOddType(matchOddType);
    setActiveToggle((prevState) => {
      let toggle;
      if (lastSelectionId && lastSelectionId === selectionId) {
        toggle = true;
      } else {
        toggle = true;
      }
      return {
        ...prevState,
        [lastSelectionId]: false, // Disable the previous selection
        [selectionId]: toggle, // Toggle the current selection
      };
    });
    // Update the last selection ID to the current one
    setOddValue(oddValue);
    setLastSelectionId(selectionId);
  };

  const getBetAmount = (betData, matchSelectionId) => {
    if (
      betData?.profit?.selectionId === matchSelectionId &&
      betData.profit.amount
    ) {
      return betData.profit.amount.toFixed(2);
    }

    if (
      betData?.loss?.selectionId === matchSelectionId &&
      betData.loss.amount
    ) {
      return betData.loss.amount.toFixed(2);
    }

    return "";
  };
  useEffect(() => {
    if (socket) {
      const message = {
        action: "roomJoin",
        message: {
          event_type: eventType,
          event_id: eventId,
        },
      };
      try {
        socket.send(JSON.stringify(message));
      } catch (err) {
        console.error(err);
      }
      socket.addEventListener("message", (data) => {
        try {
          const parsedData = JSON.parse(data?.data);

          if (parsedData?.matchOdds) {
            setMatchOddsData(parsedData?.matchOdds);
          }

          if (parsedData?.bookMakerOdds) {
            setBookmakerData(
              JSON.parse(JSON.parse(parsedData?.bookMakerOdds)[0])
            );
          }
          let categorizedData = null;
          if (!parsedData?.fancyOdds?.code) {
            if (!categorizedData) {
              console.log("Categorized data not");
              categorizedData = getNormalizedFancyData(parsedData?.fancyOdds);
            }
          }
          if (categorizedData) {
            const [fancyLayData, fancyBackData] =
              getFancyPrices(categorizedData);
            setLayFancyData(fancyLayData);
            setBackFancyData(fancyBackData);
          }
          // setFancyOdds(categorizedData);
        } catch (error) {
          console.error("Error parsing message data:", error);
        }
      });

      socket.addEventListener("close", () => {
        socket.close();
        setMatchOddsData(null);
        setBookmakerData(null);
        setFancyOdds(null);
      });
    }
  }, [socket]);

  const handleToggleClick = () => {
    setShowContent(!showContent); // Toggle between true and false
  };
  const openLoginModal = () => {
    setLoginModalOpen(true);
    setRegisterModalOpen(false);
  };

  const openRegisterModal = () => {
    setRegisterModalOpen(true);
    setLoginModalOpen(false);
  };

  const closeModals = () => {
    setLoginModalOpen(false);
    setRegisterModalOpen(false);
  };

  const getOtherTeamSelectionId = () => {
    switch (matchOddType) {
      case "Match Odds":
        return lastSelectionId === matchOddsData?.runners[0]?.selectionId
          ? matchOddsData?.runners[1]?.selectionId
          : matchOddsData?.runners[0]?.selectionId;
      case "Bookmaker Odds":
        return lastSelectionId === bookMakerData?.runners[0]?.selectionId
          ? bookMakerData?.runners[1]?.selectionId
          : bookMakerData?.runners[0]?.selectionId;
    }
  };

  const getOtherTeamName = () => {
    return teamName === matchOddsData?.runners[0]?.runner
      ? matchOddsData?.runners[1]?.runner
      : matchOddsData?.runners[0]?.runner;
  };
  const displayRunningStatus = (status, price, betType, selectionId) => {
    console.log({ status, price, betType, selectionId }, "Status Here");
    const displayStatus =
      status === "Ball Running"
        ? "Ball Running"
        : status === "SUSPENDED" || price === 0
        ? "Suspended"
        : price;

    return displayStatus;
  };
  dispatch(
    setMatchInfo({
      event_id: eventId,
      event_type: eventType,
      market_id: matchOddsData ? matchOddsData.marketId : "",
      selectionId: lastSelectionId,
      team_name: teamName,
      other_team_name: getOtherTeamName(),
      otherTeamSelectionId: getOtherTeamSelectionId() || "",
    })
  );
  const mobiletabs = [
    {
      icon: <BsGraphDown />,
      label: "Scope",
      content: (
        <div>
          <div className="graph-placeholder h-64 w-full bg-gray-200 text-center flex items-center justify-center">
            Graph Component
          </div>
        </div>
      ),
    },
    {
      icon: <IoTv />,
      label: "Video",
      content: isUserLoggedIn && (
        <div className="h-[28rem] w-full">
          <iframe
            src={`https://hattricks.games/?iframeSrc=https://mis2.sqmr.xyz/livetv.php?eventId=${eventId}`}
            className="w-full h-full object-fill"
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="grid grid-cols-12 gap-2 ">
        <div className="block lg:hidden  col-span-12 md:col-span-12 lg:col-span-3 ">
          <div className="bg-custon-right-side-bg rounded-lg">
            {/* Tab Buttons */}
            <div className="flex justify-center">
              {mobiletabs.map((tab, index) => (
                <button
                  key={index}
                  className={`px-4 py-2  flex items-center text-white justify-center space-x-2  w-full ${
                    activemobileTab === index
                      ? " border-b-2 border-custom-yellow text-white"
                      : "text-gray-400"
                  }`}
                  onClick={() => setActivemobileTab(index)}
                >
                  <span className="text-[17px]">{tab.icon} </span>{" "}
                  <span className="text-[17px]"> {tab.label}</span>
                </button>
              ))}
            </div>

            {/* Tab Content */}
            <div className="tab-content w-full h-hull">
              {mobiletabs[activemobileTab].content}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-12 lg:col-span-9 dark:bg-custom-bg-color rounded-lg p-2 bg-gray-200">
          {showContent && (
            <div className="bg-black rounded-lg h-[28rem] w-full">
              {isUserLoggedIn && !isMobile() && (
                <iframe
                  src={`https://hattricks.games/?iframeSrc=${LIVE_BASE_URL[
                    eventType
                  ].replace("{eventId}", eventId)}`}
                  className="w-full h-full object-fill"
                />
              )}
            </div>
          )}
          <div className="hidden lg:block bg-black rounded-lg p-2  mt-2 w-full">
            <div className="flex justify-end">
              <button className="flex justify-center items-center py-1 gap-2 rounded-md px-3 bg-green-400 text-white">
                {showContent ? (
                  <FaRegEye className="text-[15px" />
                ) : (
                  <FaEyeSlash className="text-[15px" />
                )}
                <p className="font-[700] text-[15px] font-montserrat">Score</p>
              </button>
            </div>
          </div>
          <p className="flex justify-start items-center mt-2 text-white font-[500] whitespace-nowrap text-[12px] md:text-[15px] lg:text-[15px] font-montserrat">
            {" "}
            <span>{SPORT[eventType]}</span>{" "}
            <span>
              <GoDotFill />
            </span>{" "}
            <span>
              {`${matchOddsData?.runners[0]?.runner || ""}`} vs{" "}
              {`${matchOddsData?.runners[1]?.runner || ""}`}
            </span>
          </p>

          <div className="Matches mt-2 w-32 py-[5px] px-2 flex mb-2 justify-center bg-slate-100 ">
            <p className="text-[14px] font-[800] whitespace-nowrap text-black font-montserrat">
              Match Odds
            </p>
          </div>

          {matchOddsData ? (
            matchOddsData?.runners?.map((match, index) => (
              <>
                <div
                  key={index}
                  className=" hidden  lg:flex justify-between items-center mb-1 p-1 dark:bg-custom-background-color bg-gray-200"
                >
                  <div>
                    <p className="text-white uppercase font-montserrat text-[13px] font-[400]">
                      {match.runner}
                    </p>

                    {matchOddType == "Match Odds" && (
                      <p
                        className={`${
                          betData?.profit.selectionId == match.selectionId
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {" "}
                        <p>{getBetAmount(betData, match.selectionId)}</p>
                      </p>
                    )}
                  </div>
                  <div className="flex  justify-center items-center gap-1">
                    <div className="flex justify-center items-center gap-1">
                      {match?.back.length ? (
                        <button
                          className={`bg-blue-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                          onClick={() => {
                            handleBetAction(
                              "back",
                              "Match Odds",
                              match?.back[0]?.price,
                              match?.selectionId,
                              match?.runner
                            );
                          }}
                        >
                          <p className="text-[13px] font-bold">
                            {match?.back[0]?.price}
                          </p>
                          <p className="text-[13px]">{match?.back[0]?.size}</p>
                        </button>
                      ) : (
                        <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                          No Back Data
                        </div>
                      )}
                    </div>
                    <div className="flex justify-center items-center gap-1">
                      {match?.lay.length ? (
                        <button
                          className={`bg-pink-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                          onClick={() => {
                            handleBetAction(
                              "lay",
                              "Match Odds",
                              match?.lay[0]?.price,
                              match?.selectionId,
                              match?.runner
                            );
                          }}
                        >
                          <p className="text-[13px] font-bold">
                            {match?.lay[0]?.price}
                          </p>
                          <p className="text-[13px]">{match?.lay[0]?.size}</p>
                        </button>
                      ) : (
                        <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                          No lay Data
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <>
                  {activeToggle[match.selectionId] &&
                    matchOddType == "Match Odds" &&
                    !isMobile() && (
                      <BetBox
                        profit={0}
                        odds={oddValue}
                        betType={betType}
                        matchOddType={matchOddType}
                        lastSelectionId={lastSelectionId}
                        setActiveToggle={setActiveToggle}
                      />
                    )}
                </>
              </>
            ))
          ) : (
            <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
              Suspended
            </div>
          )}

          {matchOddsData?.runners?.map((match, index) => (
            <>
              <div
                key={index}
                className="flex lg:hidden justify-between items-center mb-1 p-1 dark:bg-custom-background-color bg-gray-200"
              >
                <div>
                  <p className="text-white uppercase font-montserrat text-[13px] font-[400]">
                    {match.runner}
                  </p>

                  {matchOddType == "Match Odds" && (
                    <p
                      className={`${
                        betData?.profit.selectionId == match.selectionId
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {" "}
                      <p>{getBetAmount(betData, match.selectionId)}</p>
                    </p>
                  )}
                </div>
                <div className="flex justify-center items-center gap-1">
                  <div className="flex justify-center items-center gap-1">
                    <button
                      className={`bg-blue-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                      onClick={() => {
                        handleBetAction(
                          "back",
                          "Match Odds",
                          match?.lay[0]?.price,
                          match?.selectionId,
                          match?.runner
                        );
                      }}
                    >
                      <p className="text-[13px] font-bold">
                        {match?.back[0]?.price}
                      </p>
                      <p className="text-[13px]">{match?.back[0]?.size}</p>
                    </button>
                  </div>
                  <div className="flex justify-center items-center gap-1">
                    <button
                      className={`bg-pink-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                      onClick={() => {
                        handleBetAction(
                          "lay",
                          "Match Odds",
                          match?.lay[0]?.price,
                          match?.selectionId,
                          match?.runner
                        );
                      }}
                    >
                      <p className="text-[13px] font-bold">
                        {match?.lay[0]?.price}
                      </p>
                      <p className="text-[13px]">{match?.lay[0]?.size}</p>
                    </button>
                  </div>
                </div>
              </div>
              <>
                {activeToggle[match.selectionId] &&
                  matchOddType === "Match Odds" &&
                  isMobile() && (
                    <BetBox
                      profit={0}
                      odds={oddValue}
                      betType={betType}
                      matchOddType={matchOddType}
                      lastSelectionId={lastSelectionId}
                      setActiveToggle={setActiveToggle}
                    />
                  )}
              </>
            </>
          ))}

          <div className="Matches mt-2 w-32 py-[5px] px-2 flex mb-2 justify-center bg-slate-100 ">
            <p className="text-[14px] uppercase font-[800] whitespace-nowrap text-black font-montserrat">
              Bookmaker
            </p>
          </div>
          <div>
            {bookMakerData ? (
              bookMakerData?.runners?.map((match, index) => (
                <>
                  <div
                    key={index}
                    className="flex justify-between items-center mb-1 p-1 dark:bg-custom-background-color bg-gray-200"
                  >
                    <div>
                      <p className="text-white uppercase font-montserrat text-[13px] font-[400]">
                        {eventType === "4" ? match.runnerName : match.runner}
                      </p>

                      {matchOddType == "Bookmaker Odds" && (
                        <p
                          className={`${
                            betData?.profit.selectionId == match.selectionId
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {" "}
                          <p>{getBetAmount(betData, match.selectionId)}</p>
                        </p>
                      )}
                    </div>
                    <div className="flex  justify-center items-center gap-1">
                      <div className="flex justify-center items-center gap-1">
                        {match?.back.length ? (
                          <button
                            className={`bg-blue-400 rounded-md px-4 leading-none py-2  text-white w-[7rem]`}
                            disabled={!match?.back[0]?.price}
                            onClick={() => {
                              handleBetAction(
                                "back",
                                "Bookmaker Odds",
                                match?.back[0]?.price,
                                match?.selectionId,
                                eventType === "4"
                                  ? match.runnerName
                                  : match.runner
                              );
                            }}
                          >
                            <p className="text-[13px] font-bold">
                              {match?.back[0]?.price || "Suspended"}
                            </p>
                            <p className="text-[13px]">
                              {match?.back[0]?.price
                                ? match?.back[0]?.size
                                : ""}
                            </p>
                          </button>
                        ) : (
                          <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                            No Back Data
                          </div>
                        )}
                      </div>
                      <div className="flex justify-center items-center gap-1">
                        {match?.lay.length ? (
                          <button
                            className={`bg-pink-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                            disabled={!match?.lay[0]?.price}
                            onClick={() => {
                              handleBetAction(
                                "lay",
                                "Bookmaker Odds",
                                match?.lay[0]?.price,
                                match.selectionId,
                                eventType === "4"
                                  ? match.runnerName
                                  : match.runner
                              );
                            }}
                          >
                            <p className="text-[13px] font-bold">
                              {match?.lay[0]?.price || "Suspended"}
                            </p>
                            <p className="text-[13px]">
                              {match?.lay[0]?.price ? match?.lay[0]?.size : ""}
                            </p>
                          </button>
                        ) : (
                          <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                            No lay Data
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <>
                    {activeToggle[match.selectionId] &&
                      matchOddType == "Bookmaker Odds" && (
                        <BetBox
                          profit={0}
                          odds={oddValue}
                          betType={betType}
                          matchOddType={matchOddType}
                          lastSelectionId={lastSelectionId}
                          setActiveToggle={setActiveToggle}
                        />
                      )}
                  </>
                </>
              ))
            ) : (
              <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                Suspended
              </div>
            )}
          </div>

          <div className="lg:flex sm:block justify-between items-center">
            <div className="Matches mt-2 w-32 py-[5px] px-2 flex mb-2 justify-center bg-slate-100 ">
              <p className="text-[14px] uppercase font-[800] whitespace-nowrap text-black font-montserrat">
                Fancy
              </p>
            </div>
            <div className="flex justify-center items-center ">
              {fancyOdds && !fancyOdds?.code && eventType === "4"
                ? cricketMenuItems.map((tab, index) => (
                    <button
                      key={index}
                      className={`px-4 sm:w-14 lg:w-20 text-[12px] lg:text-[15px] text-center rounded-lg py-1 ml-2 ${
                        activeTab === index
                          ? "bg-custom-background-color border border-custom-yellow text-white"
                          : "border border-blue-600 text-indigo-50"
                      }`}
                      onClick={() => {
                        setActiveTab(index);
                        setActiveCategory(tab);
                      }} // Change active tab based on index
                    >
                      {tab}
                    </button>
                  ))
                : otherSportsMenuItems.map((tab, index) => (
                    <button
                      key={index}
                      className={`px-4 sm:w-14 lg:w-20 text-[12px] lg:text-[15px] text-center rounded-lg py-1 ml-2 ${
                        activeTab === index
                          ? "bg-custom-background-color border border-custom-yellow text-white"
                          : "border border-blue-600 text-indigo-50"
                      }`}
                      onClick={() => {
                        setActiveTab(index);
                        setActiveCategory(tab);
                      }}
                    >
                      {tab}
                    </button>
                  ))}
            </div>
          </div>
          <div>
            <div>
              {fancyOdds && !fancyOdds?.code ? (
                fancyOdds[activeCategory]?.map((data) => {
                  const matchingLayData = layFancyData?.find(
                    (layData) => layData[data.SelectionId]
                  );
                  const matchingbackData = backFancyData?.find(
                    (backData) => backData[data?.SelectionId]
                  );
                  return (
                    <>
                      <div
                        key={data.SelectionId}
                        className="flex justify-between items-center mb-1 p-1 dark:bg-custom-background-color bg-gray-200"
                      >
                        <div>
                          <p className="text-white text-[12px] capitalize font-montserrat font-[400]">
                            {data.RunnerName}
                          </p>
                        </div>

                        <div className="flex justify-center items-center gap-1">
                          {matchingbackData ? (
                            <button
                              key={`${data.SelectionId}-back`}
                              disabled={
                                displayRunningStatus(
                                  matchingbackData[data.SelectionId][0]?.status,
                                  matchingbackData[data.SelectionId][0]?.price,
                                  "back",
                                  data.SelectionId
                                ) == "Suspended" ||
                                displayRunningStatus(
                                  matchingbackData[data.SelectionId][0]?.status,
                                  matchingbackData[data.SelectionId][0]?.price,
                                  "back",
                                  data.SelectionId
                                ) == "Ball Running"
                              }
                              className={`bg-blue-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                              onClick={() => {
                                console.log(data?.gtype);
                                handleBetAction(
                                  "back",
                                  "Fancy Odds",
                                  displayRunningStatus(
                                    matchingbackData[data.SelectionId][0]
                                      ?.status,
                                    matchingbackData[data.SelectionId][0]
                                      ?.price,
                                    "back",
                                    data.SelectionId
                                  ) != "Suspended" ||
                                    displayRunningStatus(
                                      matchingbackData[data.SelectionId][0]
                                        ?.status,
                                      matchingbackData[data.SelectionId][0]
                                        ?.price,
                                      "lay",
                                      data.SelectionId
                                    ) != "Ball Running"
                                    ? data?.gtype == "session"
                                      ? matchingbackData[data.SelectionId][0]
                                          ?.size
                                      : matchingbackData[data.SelectionId][0]
                                          ?.price
                                    : "",
                                  data.SelectionId
                                );
                              }}
                            >
                              <p className="text-[13px] font-bold">
                                {displayRunningStatus(
                                  matchingbackData[data.SelectionId][0]?.status,
                                  matchingbackData[data.SelectionId][0]?.price,
                                  "back",
                                  data.SelectionId
                                )}
                              </p>
                              <p className="text-[13px]">
                                {matchingbackData[data.SelectionId][0]?.price &&
                                matchingbackData[data.SelectionId][0]?.size &&
                                displayRunningStatus(
                                  matchingbackData[data.SelectionId][0]?.status,
                                  matchingbackData[data.SelectionId][0]?.price,
                                  "back",
                                  data.SelectionId
                                ) != "Suspended" &&
                                displayRunningStatus(
                                  matchingbackData[data.SelectionId][0]?.status,
                                  matchingbackData[data.SelectionId][0]?.price,
                                  "back",
                                  data.SelectionId
                                ) != "Ball Running"
                                  ? matchingbackData[data.SelectionId][0]?.size
                                  : ""}
                              </p>
                            </button>
                          ) : (
                            <button
                              disabled
                              className="bg-blue-400 rounded-md px-4 leading-none py-2 text-white w-[7rem] font-bold"
                            >
                              Not Available
                            </button>
                          )}
                          {matchingLayData ? (
                            <button
                              key={`${data.SelectionId}-lay`}
                              disabled={
                                displayRunningStatus(
                                  matchingLayData[data.SelectionId][0]?.status,
                                  matchingLayData[data.SelectionId][0]?.price,
                                  "lay",
                                  data.SelectionId
                                ) == "Suspended" ||
                                displayRunningStatus(
                                  matchingLayData[data.SelectionId][0]?.status,
                                  matchingLayData[data.SelectionId][0]?.price,
                                  "lay",
                                  data.SelectionId
                                ) == "Ball Running"
                              }
                              className={`bg-pink-400 rounded-md px-4 leading-none py-2 text-white w-[7rem]`}
                              onClick={() => {
                                handleBetAction(
                                  "lay",
                                  "Fancy Odds",
                                  displayRunningStatus(
                                    matchingLayData[data.SelectionId][0]
                                      ?.status,
                                    matchingLayData[data.SelectionId][0]?.price,
                                    "lay",
                                    data.SelectionId
                                  ) != "Suspended" ||
                                    displayRunningStatus(
                                      matchingLayData[data.SelectionId][0]
                                        ?.status,
                                      matchingLayData[data.SelectionId][0]
                                        ?.price,
                                      "lay",
                                      data.SelectionId
                                    ) != "Ball Running"
                                    ? data?.gtype == "session"
                                      ? matchingLayData[data.SelectionId][0]
                                          ?.size
                                      : matchingLayData[data.SelectionId][0]
                                          ?.price
                                    : "",
                                  data.SelectionId
                                );
                              }}
                            >
                              <p className="text-[13px] font-bold">
                                {displayRunningStatus(
                                  matchingLayData[data.SelectionId][0]?.status,
                                  matchingLayData[data.SelectionId][0]?.price,
                                  "lay",
                                  data.SelectionId
                                )}
                              </p>
                              <p className="text-[13px]">
                                {matchingLayData[data.SelectionId][0]?.price &&
                                matchingLayData[data.SelectionId][0]?.size &&
                                displayRunningStatus(
                                  matchingLayData[data.SelectionId][0]?.status,
                                  matchingLayData[data.SelectionId][0]?.price,
                                  "lay",
                                  data.SelectionId
                                ) != "Suspended" &&
                                displayRunningStatus(
                                  matchingLayData[data.SelectionId][0]?.status,
                                  matchingLayData[data.SelectionId][0]?.price,
                                  "lay",
                                  data.SelectionId
                                ) != "Ball Running"
                                  ? matchingLayData[data.SelectionId][0]?.size
                                  : ""}
                              </p>
                            </button>
                          ) : (
                            <button
                              disabled
                              className="bg-pink-400 rounded-md px-4 leading-none py-2 text-white w-[7rem] font-bold"
                            >
                              Not Available
                            </button>
                          )}
                        </div>
                      </div>
                      {activeToggle[data.SelectionId] && (
                        <BetBox
                          profit={0}
                          odds={oddValue}
                          betType={betType}
                          matchOddType={matchOddType}
                          lastSelectionId={lastSelectionId}
                          setActiveToggle={setActiveToggle}
                        />
                      )}
                    </>
                  );
                })
              ) : (
                <div className="bg-white text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg">
                  Suspended
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hidden lg:block col-span-12 md:col-span-3 lg:col-span-3 ">
          <div className="dark:bg-custom-bg-color p-1  rounded-lg bg-gray-200">
            <div className="bg-black justify-between items-center rounded-lg p-2 flex ">
              <div className="flex gap-1 items-center">
                <IoTv className="text-purple-600 text-[18px]" />
                <p className="font-[600] font-montserrat text-[18px] text-white">
                  Live{" "}
                </p>
              </div>
              <button
                onClick={handleToggleClick}
                className="flex gap-1 bg-orange-600 rounded-md px-2 py-1 items-center"
              >
                {showContent && isUserLoggedIn ? (
                  <RxCross2 className="text-white text-[20px]" />
                ) : (
                  <>
                    {" "}
                    <FaPlay className="text-white text-[13px]" />
                    <p className="font-[600] font-montserrat text-[13px] text-white">
                      Watch Live{" "}
                    </p>
                  </>
                )}
              </button>
            </div>
            {showContent && !isUserLoggedIn && (
              <div className="mt-2 bg-black rounded-lg h-[220px] ">
                <div className="flex justify-center items-center my-auto">
                  <div className=" space-y-1 mt-14">
                    <p className="text-white font-[500]">
                      Please login to view{" "}
                    </p>
                    <button
                      onClick={openLoginModal}
                      className="bg-yellow-500 text-white w-full font-[500] px-2 py-1 rounded-md"
                    >
                      Sign IN{" "}
                    </button>
                    <button
                      onClick={openRegisterModal}
                      className="bg-yellow-500 text-white w-full font-[500] px-2 py-1 rounded-md"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <LoginModal
        isModalOpen={isLoginModalOpen}
        closeModal={closeModals}
        openRegisterModal={openRegisterModal}
      />
      <RegisterModal
        isModalOpen={isRegisterModalOpen}
        isloginModelOpen={isLoginModalOpen}
        closeModal={closeModals}
        openLoginModal={openLoginModal}
      />
      <ToastContainer />
    </div>
  );
}
export default Sport_by;
