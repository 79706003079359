import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";
import { userEndpoints } from "./userEndpoint";
import { betEndpoints } from "./betEndpoint";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    ...userEndpoints(builder),
    ...betEndpoints(builder)
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useForgotPasswordMutation,
  useGetUserDetailsQuery,
  usePlaceBetMutation,
} = api;
