import React from "react";
import LiveEvents from "../../component/LiveEvents/LiveEvents";

const DarkModeToggle = () => {
  return (
    <div>
      <LiveEvents />
    </div>
  );
};

export default DarkModeToggle;
