import { useEffect, useState } from "react";
import { selectionAmount } from "../../data/constants";
import { selectBetData, selectMatchData } from "../../Redux/selector";
import { useSelector, useDispatch } from "react-redux";
import {
  calculateProfit,
  convertIntToDecimal,
  getBetAmount,
} from "../../data/helper";
import { clearBetInfo, setBetInfo } from "../../Redux/features/betSlice";
import { usePlaceBetMutation } from "../../Redux/ApiSlice";
import { Audio, TailSpin } from "react-loader-spinner";
import "./Loader.css";
import { toast } from "react-toastify";
export const BetBox = ({
  odds,
  betType,
  lastSelectionId,
  setActiveToggle,
  matchOddType,
}) => {
  const [amount, setAmount] = useState(0);
  const matchData = useSelector(selectMatchData);
  const betData = useSelector(selectBetData);
  const [placeBet, { isLoading: isPlaceBetLoading }] = usePlaceBetMutation();
  const dispatch = useDispatch();
  const handleClick = (elem) => {
    setAmount(elem);
  };
  useEffect(() => {
    handleCalculateProfit();
  }, [amount]);

  const notify = (message) => toast(message);
  const handleBetPlace = async () => {
    console.log(betData);
    const res = await placeBet(betData).unwrap();
    if (res.message) {
      notify(res?.data?.message);
    }
  };
  const handleCalculateProfit = () => {
    const result = calculateProfit(
      odds,
      amount,
      matchData?.selectionId,
      matchOddType,
      matchData?.otherTeamSelectionId,
      betType
    );
    const commonPayload = {
      betType,
      selectionId: matchData?.selectionId,
      otherTeamSelectionId: matchData?.otherTeamSelectionId,
      team_name: matchData?.team_name,
      other_team_name: matchData?.other_team_name,
      matchId: matchData?.market_id,
      matchOddType: matchOddType,
      oddValue: odds,
    };
    // console.log(commonPayload);
    if (betType === "back") {
      dispatch(
        setBetInfo({
          ...commonPayload,
          profit: result[matchData?.selectionId],
          loss: result[matchData?.otherTeamSelectionId],
          stack: amount,
        })
      );
    } else {
      dispatch(
        setBetInfo({
          ...commonPayload,
          profit: result[matchData?.otherTeamSelectionId],
          loss: result[matchData?.selectionId],
          stack: amount,
        })
      );
    }
  };
  // const getBetAmount = (betData, matchSelectionId) => {
  //   if (
  //     betData?.profit?.selectionId === matchSelectionId &&
  //     betData.profit.amount
  //   ) {
  //     return betData.profit.amount.toFixed(2);
  //   }

  //   if (
  //     betData?.loss?.selectionId === matchSelectionId &&
  //     betData.loss.amount
  //   ) {
  //     return betData.loss.amount.toFixed(2);
  //   }

  //   return "";
  // };
  if (isPlaceBetLoading) {
    return (
      <div className="loader-overlay">
        <TailSpin
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }
  return (
    <div
      className={`text-custom-yellow px-3 py-2 font-montserrat font-semibold text-[15px] rounded-lg ${
        betType && betType == "back" ? "bg-blue-400" : "bg-pink-400"
      }`}
    >
      <div className="bg-light-blue p-4 rounded-lg space-y-2 md:space-y-0 shadow-md">
        <div className="grid grid-cols-2 gap-2">
          <div className="text-white py-1 px-3 rounded-md text-center font-semibold text-sm">
            {betType == "back" ? "Profit" : "Loss"} :{" "}
            {getBetAmount(betData, lastSelectionId)}
          </div>

          <div className="flex items-center justify-center space-x-2">
            <span className="text-gray-600 text-sm font-semibold">Odd</span>
            <input
              type="text"
              value={convertIntToDecimal(odds)}
              readOnly
              className="w-12 sm:w-16 text-center border border-gray-300 rounded-md bg-white text-blue-600 font-semibold text-sm md:text-base"
            />
          </div>

          {/* Stake Section */}
          <div className="flex items-center justify-center space-x-2">
            <span className="text-gray-600 text-sm font-semibold">Stake</span>
            <input
              type="text"
              value={amount}
              onChange={(event) => setAmount(event?.target?.value)}
              className="w-12 sm:w-16 text-center border border-gray-300 rounded-md bg-white text-blue-600 font-semibold text-sm md:text-base"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex space-x-2 justify-center">
            <button
              className="bg-red-500 text-white text-xs md:text-sm py-1 px-3 md:px-4 rounded-md hover:bg-red-600"
              onClick={() => {
                dispatch(clearBetInfo(""));
                setActiveToggle({ [lastSelectionId]: false });
              }}
            >
              Cancel
            </button>
            <button
              className="bg-blue-600 text-white text-xs md:text-sm py-1 px-3 md:px-4 rounded-md hover:bg-blue-700 whitespace-nowrap"
              onClick={() => handleBetPlace()}
            >
              Place Bet
            </button>
          </div>
        </div>
      </div>

      {/* Selection Amount Buttons */}
      <div className="flex flex-wrap justify-center space-x-2 mt-2 bg-light-blue py-2 rounded-lg">
        {selectionAmount.map((elem) => (
          <button
            onClick={() => handleClick(elem)}
            key={elem}
            className="px-3 sm:px-4 py-1 text-[10px] sm:text-[12px] lg:text-[15px] text-center rounded-lg border border-blue-600 text-blue-600 bg-white"
          >
            {elem}
          </button>
        ))}
      </div>
    </div>
  );
};
