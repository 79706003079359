import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context
const SocketContext = createContext();

// Hook to use the context in components
export const useSocket = () => useContext(SocketContext);

// Create provider component
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Create WebSocket connection
    const newSocket = new WebSocket(process.env.REACT_APP_WS_URL);

    // Set the socket
    newSocket.onopen = () => {
      console.log("Websocket is open");
      setSocket(newSocket);
    };

    // Error handling
    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket closed:", event);
      setSocket(null); // Clear socket reference
    };

    // Cleanup on unmount
    return () => {
      console.log(newSocket.readyState);
      if (newSocket.readyState === WebSocket.OPEN) {
        newSocket.close();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
