import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =
  "https://jdda5hq5pl.execute-api.eu-north-1.amazonaws.com/Stage/";

// Check if the baseUrl is properly set
if (!baseUrl) {
  throw new Error(
    "VITE_API_BASE_URL is not defined in the environment variables."
  );
}

export const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers) => {
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set("Accept-Encoding", "identity");
    return headers;
  },
});
