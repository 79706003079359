import axios from "axios";
import { setUserDetails } from "./userSlice";
export const betEndpoints = (builder) => ({
    placeBet: builder.mutation({
        query: (credential) => ({
            url : '/place-bet',
            method: "POST",
            headers: {
                "Authorization" : localStorage.getItem("access_token")
            },
            body: credential,

        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
            try{
                const { data } = await queryFulfilled; // Await the fulfilled query
                const userDetails = await axios.get("https://jdda5hq5pl.execute-api.eu-north-1.amazonaws.com/Stage/user-details", {headers:{"Authorization": localStorage.getItem("access_token")}}); 
                const {data: userData} = userDetails
                dispatch(setUserDetails(userData))
            }catch(err){
                console.error(err)
            }
        }
    })
})