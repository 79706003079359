import { configureStore } from "@reduxjs/toolkit";
import { api } from "./ApiSlice"; // Change this to import `api`
import userReducer from "./userSlice";
import betReducer from "./features/betSlice";
import matchDetailsReducer from "./features/matchDetailsSlice";
export const store = configureStore({
  reducer: {
    user: userReducer,
    bet: betReducer,
    matchDetails: matchDetailsReducer,
    [api.reducerPath]: api.reducer, // Use `api` instead of `apiSlice`
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
});
