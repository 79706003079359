import { v4 as uuidv4 } from "uuid";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetUserDetailsQuery } from "../Redux/ApiSlice";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const { data, error, isLoading } = useGetUserDetailsQuery();
  const [userDeviceId, setUserDeviceId] = useState(() => {
    const storedDeviceId = localStorage.getItem("device_id");
    if (storedDeviceId) return storedDeviceId;

    const newDeviceId = uuidv4();
    localStorage.setItem("device_id", newDeviceId);
    return newDeviceId;
  });

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(() => {
    const token = localStorage.getItem("access_token");
    return !!token && token !== "undefined";
  });

  const [userData, setUserData] = useState({});
  const [ip, setIp] = useState("");

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIp(data.ip);
        localStorage.setItem("ip", data.ip);
      })
      .catch((error) => console.error("Error fetching IP:", error));

    localStorage.setItem("ip", ip);
  }, []);
  useEffect(() => {
    // This effect will now only run if `userDeviceId` changes, but ideally it won’t run again after initial load
    const token = localStorage.getItem("access_token");
    if (!!token && token !== "undefined") {
    }
    setIsUserLoggedIn(!!token && token !== "undefined");
  }, [userDeviceId, isUserLoggedIn]);

  useEffect(() => {
    if (data && !error && !isLoading) {
      setUserData(data?.data || {});
    }
  }, [data, error, isLoading, isUserLoggedIn]);

  return (
    <UserContext.Provider
      value={{ userDeviceId, isUserLoggedIn, userData, ip }}
    >
      {children}
    </UserContext.Provider>
  );
};
