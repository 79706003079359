import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profit: {},
  loss: {},
  selectionId: "", // Also known as Team ID
  otherTeamSelectionId: "",
  matchId: "",
  matchOddType: "",
  betType: "",
  oddValue: "",
  IP_address: "",
  stack: "",
};
const betSlice = createSlice({
  name: "betSlice",
  initialState,
  reducers: {
    setBetInfo: (state, action) => {
      const {
        betType,
        selectionId,
        otherTeamSelectionId,
        team_name,
        other_team_name,
        profit,
        matchId,
        matchOddType,
        stack,
        loss,
        oddValue,
      } = action.payload;

      state.betType = betType;
      state.selectionId = selectionId;
      state.matchOddType = matchOddType;
      state.matchId = matchId;
      state.stack = stack;
      state.otherTeamSelectionId = otherTeamSelectionId;
      state.oddValue = oddValue;
      state.IP_address = localStorage.getItem("ip");
      if (betType === "back") {
        state.profit = {
          selectionId: selectionId,
          team_name: team_name,
          amount: profit,
        };
        state.loss = {
          selectionId: otherTeamSelectionId,
          team_name: other_team_name,
          amount: loss,
        };
      } else {
        state.loss = {
          selectionId: selectionId,
          team_name: team_name,
          amount: loss,
        };
        state.profit = {
          selectionId: otherTeamSelectionId,
          team_name: other_team_name,
          amount: profit,
        };
      }
    },
    clearBetInfo: (state, action) => {
      state.betType = "";
      state.matchId = "";
      state.selectionId = "";
      state.profit = {};
      state.loss = {};
    },
  },
});

export const { setBetInfo, clearBetInfo } = betSlice.actions;
export default betSlice.reducer;
