import axios from "axios";
import { setCredentials, setUserDetails } from "./userSlice"; // Adjust the import path as necessary

export const userEndpoints = (builder) => ({
  signUp: builder.mutation({
    query: (credentials) => ({
      url: `/user-register`, // Adjust to your actual endpoint
      method: "POST",

      body: credentials,
    }),
  }),
  signIn: builder.mutation({
    query: (credentials) => ({
      url: `/user-login`, // Adjust to your actual endpoint
      method: "POST",
      headers: {
        "Device-Token": localStorage.getItem("device_id"),
      },
      body: credentials,
    }),
    // Handle side effects on successful login
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      try {
        const { data } = await queryFulfilled; // Await the fulfilled query
        const userDetails = await axios.get("https://jdda5hq5pl.execute-api.eu-north-1.amazonaws.com/Stage/user-details", {headers:{"Authorization": data?.data?.accessToken}}); 
        // Assuming your response structure contains the token and user
        const accessToken = data?.data?.accessToken; // Adjust based on your API response
        const refreshToken = data?.data?.refreshToken; // Adjust based on your API response
        // const user = data.user; // Adjust based on your API response
        // Store token and user in Redux
        const {data: userData} = userDetails
        dispatch(setCredentials({ accessToken, refreshToken }));
        dispatch(setUserDetails(userData))
        // Store token in localStorage
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", refreshToken);
      } catch (error) {
        console.error("Sign in failed:", error);
        // Optionally handle error or dispatch an action
      }
    },
  }),
  forgotPassword: builder.mutation({
    query: (credentials) => {
      return {
        url: `/user_reset_password_token`,
        method: "POST",
        body: credentials,
      };
    },
  }),
  getUserDetails: builder.query({
    query: (credentials) => ({
      url: `/user-details`, // Adjust to your actual endpoint
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("access_token"),
      },
    }),
  }),
});
